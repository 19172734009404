import { Button, Input, Select, SelectItem, Textarea, user } from "@nextui-org/react";
import { Formik, Form } from "formik";
import { Text, client } from "../../utils/client";
import { useModal } from "../../hooks/useModal";
import SelectUser from "./Fields/SelectUser";

type TextFormProps = {
  text: Text;
};

export default function TextEditForm({ text }: TextFormProps) {
  const { closeModal } = useModal();

  const onSubmit = async (data: any) => {
    data.status = parseInt(data.status);

    // Merge the surfer object
    data.surfer = {
      ...text.surfer,
      ...data.surfer,
    };

    await client.service("texts").patch(text._id.toString(), data);
    closeModal();
  };

  const removeText = async () => {
    await client.service("texts").remove(text._id.toString());
    closeModal();
  };

  const statuses = [
    { key: 1, label: "Beställd" },
    { key: 2, label: "Mottagen" },
    { key: 3, label: "Text klar" },
    { key: 4, label: "Skickad till kund för korrektur" },
    { key: 5, label: "Klar för publicering" },
    { key: 6, label: "Publicerad" },
  ];

  return (
    <Formik
      initialValues={{
        description: text.description,
        url: text.url,
        title: text.title,
        metadescription: text.metadescription,
        surfer: {
          link: text.surfer?.link ?? "",
          start: text.surfer?.start ?? 0,
          best: text.surfer?.best ?? 0,
          score: text.surfer?.score ?? 0,
        },
        status: text.status,
        userId: text.userId,
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, handleChange, handleBlur, handleSubmit, dirty }) => (
        <Form onSubmit={handleSubmit}>
          <div className="space-y-2">
            <Textarea
              fullWidth
              name="description"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="Beskrivning"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="url"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="URL"
              value={values.url}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="title"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="Titel"
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="metadescription"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="Meta Description"
              value={values.metadescription}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="surfer.link"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="Surfer länk"
              value={values.surfer?.link ?? ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="surfer.start"
              labelPlacement="inside"
              variant="flat"
              label="Surfer start"
              type="number"
              value={values.surfer?.start?.toString() ?? "0"}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="surfer.best"
              labelPlacement="inside"
              variant="flat"
              label="Surfer bäst"
              type="number"
              value={values.surfer?.best?.toString() ?? "0"}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="surfer.score"
              labelPlacement="inside"
              variant="flat"
              label="Surfer score"
              type="number"
              value={values.surfer?.score?.toString() ?? "0"}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Select name="status" onChange={handleChange} onBlur={handleBlur} label="Välj status">
              {statuses.map((status) => (
                <SelectItem key={status.key}>{status.label}</SelectItem>
              ))}
            </Select>
            <div className="my-2">
              <SelectUser
                name="userId"
                label="Copywriter"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.userId}
              />
            </div>
          </div>
          <div className="flex justify-end mt-5 space-x-5">
            {!dirty && (
              <Button onClick={removeText} type="button" color="danger">
                Ta bort
              </Button>
            )}

            {dirty && (
              <Button type="submit" color="primary">
                Spara
              </Button>
            )}
            <Button onClick={closeModal} type="button">
              Avbryt
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
