import DynamicBarChart from "../../components/Charts/DynamicBarChart";

type SEOProps = {
  report: any;
  month: any;
};

export default function SEM({ report, month }: SEOProps) {
  return (
    <div className="grid grid-cols-2 gap-8">
      <div className="p-6 panel">
        <div className="mb-4">
          <h2 className="text-xs text-slate-500">Trend</h2>
          <p className="text-base text-gray">Antal besökare från SEM</p>
        </div>
        <DynamicBarChart
          traffic={report.traffic}
          selectedChannel={"Paid Search"}
          selectedMetric="sessions"
          barName="Trafik"
          color="#006fee"
        />
      </div>
      <div className="p-6 panel">
        <div className="mb-4">
          <h2 className="text-xs text-slate-500">Trend</h2>
          <p className="text-base text-gray">Antal Konverteringar från SEM</p>
        </div>
        <DynamicBarChart
          traffic={report.traffic}
          selectedChannel={"Paid Search"}
          selectedMetric="conversions"
          barName="Konverteringar"
          color="#45d483"
        />
      </div>
      <div className="p-6 panel">
        <div className="mb-4">
          <h2 className="text-xs text-slate-500">Trend</h2>
          <p className="text-base text-gray">Engagemangsgrad från SEM</p>
        </div>
        <DynamicBarChart
          traffic={report.traffic}
          selectedChannel={"Paid Search"}
          selectedMetric="engagementRate"
          barName="Engagemangsgrad"
          color="#17c964"
        />
      </div>
      <div className="p-6 panel">
        <div className="mb-4">
          <h2 className="text-xs text-slate-500">Trend</h2>
          <p className="text-base text-gray">Avvisningsfrekvens från SEM</p>
        </div>
        <DynamicBarChart
          traffic={report.traffic}
          selectedChannel={"Paid Search"}
          selectedMetric="bounceRate"
          barName="Avvisningsfrekvens"
          color="#f5a524"
        />
      </div>
    </div>
  );
}
