import { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { client, Text } from "../../utils/client";
import { DataContext } from "../../context/DataContext";
import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Input } from "@nextui-org/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useRenderCell } from "../../hooks/useRenderCell";

export default function Texts() {
  const { texts } = useContext(DataContext);
  const [textsArray, setTextsArray] = useState<Text[]>([]);
  const [filterValue, setFilterValue] = useState("");
  const renderCell = useRenderCell();

  useEffect(() => {
    const fetchTexts = async () => {
      const res = await client.service("texts").find({
        query: { status: { $ne: 6 } },
      });
      setTextsArray(res.data);
    };
    fetchTexts();
  }, [texts]);

  // Filter the items based on filterValue
  const filteredItems = useMemo(() => {
    if (!filterValue) return textsArray;
    return textsArray.filter(
      (item: Text) =>
        item.title?.toLowerCase().includes(filterValue.toLowerCase()) ||
        item.url?.toLowerCase().includes(filterValue.toLowerCase()) ||
        item.description?.toLowerCase().includes(filterValue.toLowerCase())
    );
  }, [textsArray, filterValue]);

  const onSearchChange = useCallback((value: string) => {
    setFilterValue(value);
  }, []);

  const onClear = useCallback(() => {
    setFilterValue("");
  }, []);

  const columns = [
    { key: "customerId", label: "KUND" },
    { key: "texttitlemetaurl", label: "TEXT" },
    { key: "hours", label: "TIMMAR" },
    { key: "textStatus", label: "STATUS" },
    { key: "deadline", label: "DEADLINE" },
    { key: "userId", label: "COPYWRITER" },
    { key: "createdAt", label: "SKAPAD" },
    { key: "updatedAt", label: "UPPDATERAD" },
    { key: "textActions", label: "" },
  ];

  return (
    <>
      <div className="flex justify-between mb-10">
        <h1 className="text-2xl font-medium text-gray">Texter</h1>
      </div>
      <Table
        classNames={{ wrapper: "shadow-none" }}
        className="panel"
        aria-label="Texts table with search"
        // Add a topContent to hold the search input:
        topContent={
          <div className="flex justify-end mb-4">
            <Input
              isClearable
              placeholder="Sök..."
              className="w-full"
              startContent={<MagnifyingGlassIcon className="w-4 h-4" />}
              value={filterValue}
              onClear={onClear}
              onValueChange={onSearchChange}
            />
          </div>
        }
      >
        <TableHeader columns={columns}>
          {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
        </TableHeader>
        <TableBody emptyContent={"Inga texter hittades"} items={filteredItems}>
          {(text) => (
            <TableRow key={text._id.toString()}>
              {(columnKey) => <TableCell>{renderCell(text, columnKey.toString())}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
}
