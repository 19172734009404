import { useState } from "react";
import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import {
  ArrowLeftStartOnRectangleIcon,
  Bars3Icon,
  CalendarIcon,
  ChartBarIcon,
  ChartPieIcon,
  ClipboardDocumentCheckIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { NavLink, Outlet } from "react-router-dom";
import Logo from "./components/Logo";
import Icon from "./components/Icon";
import UserMenu from "./components/UserMenu";
import { DataProvider } from "./context/DataContext";
import { ModalProvider } from "./context/ModalContext";

const navigation = [
  { name: "Dashboard", href: "/dashboard", icon: HomeIcon },
  { name: "Aktiviteter", href: "/activities", icon: ClipboardDocumentCheckIcon },
  { name: "Kunder", href: "/customers", icon: UsersIcon },
  { name: "Texter", href: "/texts", icon: DocumentDuplicateIcon },
  { name: "SEO", href: "/#", icon: MagnifyingGlassIcon, disabled: true },
  { name: "SEM", href: "/#", icon: CurrencyDollarIcon, disabled: true },
  { name: "Rapporter", href: "/#", icon: ChartBarIcon, disabled: true },
  { name: "Projekt", href: "/#", icon: FolderIcon, disabled: true },
  { name: "12P", href: "/#", icon: ChartPieIcon, disabled: true },
  { name: "Fakturor", href: "/#", icon: CreditCardIcon, disabled: true },
];
const teams = [
  { id: 1, name: "Heroicons", href: "#", initial: "H" },
  { id: 2, name: "Tailwind Labs", href: "#", initial: "T" },
  { id: 3, name: "Workcation", href: "#", initial: "W" },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Layout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <DataProvider>
      <ModalProvider>
        <div>
          <Transition show={sidebarOpen}>
            <Dialog className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
              <TransitionChild
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-nav/80" />
              </TransitionChild>

              <div className="fixed inset-0 flex">
                <TransitionChild
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <DialogPanel className="relative flex flex-1 w-full max-w-xs mr-16">
                    <TransitionChild
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 flex justify-center w-16 pt-5 left-full">
                        <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon className="w-6 h-6 text-gray" aria-hidden="true" />
                        </button>
                      </div>
                    </TransitionChild>
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex flex-col px-6 pb-2 overflow-y-auto bg-nav grow gap-y-5 ring-1 ring-white/10 shadow-small">
                      <div className="flex items-center h-16 shrink-0">
                        <Icon className="w-10 h-10 text-gray" />
                      </div>
                      <nav className="flex flex-col flex-1">
                        <ul role="list" className="flex flex-col flex-1 gap-y-7">
                          <li>
                            <ul role="list" className="-mx-2 space-y-1">
                              {navigation.map((item) => (
                                <li key={item.name}>
                                  <NavLink
                                    to={item.href}
                                    className={classNames(
                                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                    )}
                                  >
                                    <item.icon className="w-6 h-6 shrink-0" aria-hidden="true" />
                                    {item.name}
                                  </NavLink>
                                </li>
                              ))}
                            </ul>
                          </li>
                          <li hidden>
                            <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                            <ul role="list" className="mt-2 -mx-2 space-y-1">
                              {teams.map((team) => (
                                <li key={team.name}>
                                  <a
                                    href={team.href}
                                    className={classNames(
                                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                    )}
                                  >
                                    <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                      {team.initial}
                                    </span>
                                    <span className="truncate">{team.name}</span>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </Dialog>
          </Transition>

          {/* Static sidebar for desktop */}
          <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col ">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col px-6 overflow-y-auto bg-nav grow gap-y-5">
              <div className="flex items-center border-b h-28 shrink-0 border-grayLight ">
                <Logo className="text-gray" />
              </div>
              <nav className="flex flex-col flex-1">
                <ul role="list" className="flex flex-col flex-1 gap-y-7">
                  <li>
                    <ul role="list" className="-mx-2 space-y-1">
                      {navigation.map((item) => (
                        <li key={item.name}>
                          <NavLink
                            to={item.href}
                            onClick={(event) => item.disabled && event?.preventDefault()}
                            className={classNames(
                              "group flex gap-x-3 rounded-md p-2 text-sm leading-6 text-slate-500 font-light transition",
                              item.disabled && " hidden"
                            )}
                          >
                            <item.icon className="w-5 h-5 my-auto shrink-0" aria-hidden="true" />
                            {item.name}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li hidden>
                    <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                    <ul role="list" className="mt-2 -mx-2 space-y-1">
                      {teams.map((team) => (
                        <li key={team.name}>
                          <NavLink
                            to={team.href}
                            className={classNames("group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold")}
                          >
                            <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                              {team.initial}
                            </span>
                            <span className="truncate">{team.name}</span>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li className="pt-4 mt-auto mb-2 border-t border-grayLight">
                    <UserMenu />
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div className="sticky top-0 z-40 flex items-center px-4 py-4 shadow-sm bg-nav gap-x-6 sm:px-6 lg:hidden">
            <button type="button" className="-m-2.5 p-2.5 text-gray-400 lg:hidden" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="w-6 h-6 text-gray" aria-hidden="true" />
            </button>
            <div className="flex-1 text-sm font-semibold leading-6 text-white">Dashboard</div>
            <UserMenu size="sm" />
          </div>

          <main className="py-10 lg:pl-72">
            <div className="px-4 sm:px-6 lg:px-8">
              <Outlet />
            </div>
          </main>
        </div>
      </ModalProvider>
    </DataProvider>
  );
}
