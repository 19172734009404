import React, { useEffect, useState } from "react";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react";
import { client, Customer } from "../../utils/client";

// Adjust your columns as you wish
const landingPageColumns = [
  { name: "Landing Page", uid: "landingPage" },
  { name: "Sessions", uid: "sessions" },
  { name: "Engaged Sessions", uid: "engagedSessions" },
  { name: "Engagement Rate", uid: "engagementRate" },
  { name: "Conversions", uid: "conversions" },
];

// Matching the shape of one landing page item
interface LandingPageItem {
  landingPage: string;
  sessions: number;
  engagedSessions: number;
  engagementRate: number;
  conversions: number;
}
type AnalyticsChartProps = {
  customer: Customer;
};

export default function LandingPageTable({ customer }: AnalyticsChartProps) {
  const [landingPages, setLandingPages] = useState<LandingPageItem[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchLandingPages = async () => {
      try {
        // Adjust method call as needed: .getLandingPageData({},{}) or .find() or .get("someId"), etc.
        const response = await client.service("analytics").getLandingPageData(
          {
            gaPropertyId: customer.gaPropertyId,
          },
          {}
        );
        // The data should match the array of landing page objects you showed
        if (response.length === 0) {
          setError(
            "Kunde inte hämta data från Google Analytics. Lägg till användaren ga4-data-api-service@new-customers-report.iam.gserviceaccount.com som läsbehörig på Google Analytics."
          );
        } else {
          setLandingPages(response);
        }
      } catch (error) {
        console.error("Error fetching landing pages:", error);
      }
    };

    fetchLandingPages();
  }, []);

  // Optionally handle engagementRate as a percentage or custom format
  const renderCell = (item: LandingPageItem, columnKey: React.Key) => {
    switch (columnKey) {
      case "engagementRate":
        // e.g. 0.79 -> '79.00%'
        return `${(item.engagementRate * 100).toFixed(2)}%`;
      default:
        // Just show the raw value
        return item[columnKey as keyof LandingPageItem];
    }
  };

  return (
    <Table
      classNames={{
        wrapper: "border-0 p-0 ring-0 shadow-none max-h-[400px]",
        emptyWrapper: "text-sm",
      }}
      className="border-0"
      aria-label="Landing Pages Table"
      // You can set max-height, styling, etc. with `classNames` if desired
    >
      <TableHeader columns={landingPageColumns}>
        {(column) => <TableColumn key={column.uid}>{column.name}</TableColumn>}
      </TableHeader>
      <TableBody emptyContent={error} items={landingPages}>
        {(item) => (
          <TableRow key={item.landingPage}>
            {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
