import { useContext, useEffect, useState } from "react";
import { client, Customer, Task } from "../../utils/client";
import { Bar, BarChart, ResponsiveContainer, XAxis, Tooltip, ReferenceLine } from "recharts";

import moment from "moment";
import { DataContext } from "../../context/DataContext";

type CustomerHoursProps = {
  customer: Customer;
};

export default function CustomerHours({ customer }: CustomerHoursProps) {
  const { tasks } = useContext(DataContext);
  const [activities, setActivities] = useState<Task[]>([]);

  useEffect(() => {
    client
      .service("tasks")
      .getTaskStats({ customerId: customer._id.toString() }, {})
      .then((res) => {
        setActivities(res);
      })
      .catch((error) => {
        console.error("Error fetching task stats:", error);
      });
    console.log("activities", activities);
  }, [customer, tasks]);

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-4 bg-white border custom-tooltip rounded-xl border-grayLight">
          <p className="label">{moment(payload[0].payload._id, "YYYYMM").format("MMM YYYY")}</p>
          <p style={{ color: payload[0].fill }} className="label">{`Planerade timmar : ${payload[0].value}`}</p>
          <p style={{ color: payload[1].fill }} className="label">{`Arbetade timmar: ${payload[1].value}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="w-full h-auto p-4 rounded-lg panel">
      <div className="w-full p-3 text-xs font-semibold uppercase rounded-lg bg-default-100 text-foreground-500">
        Timbank
      </div>
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart height={100} data={activities}>
            <Tooltip content={<CustomTooltip />} />
            <Bar radius={[5, 5, 0, 0]} type="natural" barSize={10} dataKey="plannedHours" fill="#51513D" />
            <Bar radius={[5, 5, 0, 0]} type="natural" barSize={10} dataKey="workedHours" fill="#D5A438" />
            <ReferenceLine
              ifOverflow="extendDomain"
              y={customer.timebank}
              label={{ value: "Timbank", position: "insideTopRight", fill: "#D5A438" }}
              stroke="black"
              strokeDasharray="3 3"
            />
            <XAxis
              padding={{ left: 40, right: 20 }}
              tick={{ fill: "#64748b", fontSize: "14", fontWeight: 300 }}
              axisLine={false}
              tickLine={false}
              dataKey="_id"
              tickFormatter={(tick) => moment(tick, "YYYYMM").format("MMM")} // Format to short month name
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
