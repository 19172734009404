import React from "react";
import moment, { Moment } from "moment";
import { Button } from "@nextui-org/react";

interface MonthSwitcherProps {
  month: Moment;
  setMonth: React.Dispatch<React.SetStateAction<Moment>>;
}

export default function MonthSwitcher({ month, setMonth }: MonthSwitcherProps) {
  // Only allow going forward if the next month is <= current month
  const canGoNext = month.clone().add(1, "month").isSameOrBefore(moment(), "month");

  const handlePrev = () => {
    setMonth((prev) => prev.clone().subtract(1, "month"));
  };

  const handleNext = () => {
    if (canGoNext) {
      setMonth((prev) => prev.clone().add(1, "month"));
    }
  };

  return (
    <div className="flex items-center space-x-4">
      <Button isIconOnly radius="full" onClick={handlePrev}>
        {"<"}
      </Button>
      <div className="w-32 text-center first-letter:uppercase">{month.format("MMM YYYY")}</div>
      <Button isIconOnly disabled={!canGoNext} radius="full" onClick={handleNext}>
        {">"}
      </Button>
    </div>
  );
}
