import DynamicBarChart from "../../components/Charts/DynamicBarChart";

type SEOProps = {
  report: any;
  month: any;
};

export default function CRO({ report, month }: SEOProps) {
  return (
    <div className="grid grid-cols-2 gap-8">
      <div className="p-8 panel">
        <DynamicBarChart
          traffic={report.traffic}
          selectedChannel={"Organic Search"}
          selectedMetric="sessions"
          barName="Trafik"
          color="#006fee"
        />
      </div>
      <div className="p-8 panel">
        <DynamicBarChart
          traffic={report.traffic}
          selectedChannel={"Organic Search"}
          selectedMetric="conversions"
          barName="Konverteringar"
          color="#45d483"
        />
      </div>
      <div className="p-8 panel">
        <DynamicBarChart
          traffic={report.traffic}
          selectedChannel={"Organic Search"}
          selectedMetric="engagementRate"
          barName="Engagemangsgrad"
          color="#17c964"
        />
      </div>
      <div className="p-8 panel">
        <DynamicBarChart
          traffic={report.traffic}
          selectedChannel={"Organic Search"}
          selectedMetric="bounceRate"
          barName="Avvisningsfrekvens"
          color="#f5a524"
        />
      </div>
    </div>
  );
}
