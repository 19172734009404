import { Cell, Label, Pie, PieChart as RePieChart, ResponsiveContainer } from "recharts";
import { darkenHex } from "../../utils/darkenHex";

type PieChartProps = {
  title: string;
  description: string;
  value: number;
  goal: number;
  label: string;
  explanation?: string;
  color?: string;
  percentage?: boolean;
};

export default function PieChart({ value, goal, label, title, description, color, percentage = false }: PieChartProps) {
  // Calculate the percentage of the goal fulfilled and remaining
  const fulfilledPercentage = (value / goal) * 100;
  const remainingPercentage = 100 - fulfilledPercentage;

  // Prepare data for the chart
  const data = [
    { name: "Fulfilled", value: fulfilledPercentage, color: color ?? "#4caf50" }, // Green for fulfilled
    { name: "Remaining", value: remainingPercentage, color: "#f4f4f5" }, // Gray for remaining
  ];

  // Change color to a darker shade
  if (value > goal) {
    data[1].color = darkenHex(data[0].color, 0.4);
  }

  return (
    <div className="w-full h-fit">
      <div className="px-6 pt-6">
        <h2 className="text-xs text-slate-500">{title}</h2>
        <p className="text-base text-gray">{description}</p>
      </div>
      <div className="h-64">
        <ResponsiveContainer>
          <RePieChart>
            <Pie
              data={data}
              nameKey="name"
              startAngle={90} // Start at 12 o'clock
              endAngle={-270} // Fill clockwise back to 12 o'clock
              innerRadius={60}
              outerRadius={70}
              cornerRadius={10}
              paddingAngle={1}
              stroke="none"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
              <Label className="text-2xl font-medium fill-gray" position="center">
                {value + (percentage ? "%" : "")}
              </Label>
            </Pie>
          </RePieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
