import React, { useState } from "react";
import { client, Task } from "../utils/client";
import { Checkbox } from "@nextui-org/react";

type TaskStatusProps = {
  task: Task;
};

export default function TaskStatus({ task }: TaskStatusProps) {
  const [isSelected, setIsSelected] = useState(task.completed);

  const completeTask = async () => {
    setIsSelected(!isSelected);

    // If no deadline is set, set it to now
    const data: any = {
      deadline: task.deadline || Date.now(),
      completed: !task.completed,
    };

    // If task.timeTaken is 0 but timegiven is set, set timeTaken to timeGiven
    if (task.timeGiven && !task.timeTaken) {
      data.timeTaken = task.timeGiven;
    }

    await client.service("tasks").patch(task._id.toString(), data);
  };

  return <Checkbox isSelected={isSelected} onValueChange={completeTask} />;
}
