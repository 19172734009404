import React, { useEffect, useState } from "react";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from "recharts";
import moment from "moment";
import { client, Customer } from "../../utils/client";
import getColor from "../../utils/getColor"; // adjust path to your function

interface ChannelType {
  channel: string;
  sessions: number;
  engagementRate: number;
  engagedSessions: number;
  conversions: number;
}

interface AnalyticsDataItem {
  yearMonth: string;
  total: {
    sessions: number;
    engagedSessions: number;
    conversions: number;
    engagementRate: number;
  };
  channels: ChannelType[];
}

type AnalyticsChartProps = {
  customer: Customer;
};

export default function AnalyticsChart({ customer }: AnalyticsChartProps) {
  const [analyticsData, setAnalyticsData] = useState<AnalyticsDataItem[]>([]);
  const [stackedData, setStackedData] = useState<any[]>([]);
  const [channelGroups, setChannelGroups] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);

  // Use moment to get the first day of the month one year ago
  const oneYearAgo = moment().subtract(1, "year").startOf("month").format("YYYY-MM-DD");

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      try {
        // If your "get" method requires a different arg or if you do .find(), adjust accordingly
        const response = await client.service("analytics").getTrafficData(
          {
            startDate: oneYearAgo,
            endDate: "today",
            gaPropertyId: customer.gaPropertyId,
          },
          {}
        );
        // If response is empty set error
        if (response.length === 0) {
          setError(
            "Kunde inte hämta data från Google Analytics. Lägg till användaren ga4-data-api-service@new-customers-report.iam.gserviceaccount.com som läsbehörig på Google Analytics."
          );
        } else {
          setAnalyticsData(response);
        }
      } catch (error) {
        console.error("Error fetching analytics data:", error);
      }
    };
    fetchAnalyticsData();
  }, []);

  useEffect(() => {
    if (analyticsData.length === 0) return;

    // 1) Collect all unique channels across all months
    const allChannels = new Set<string>();
    analyticsData.forEach((item) => {
      item.channels.forEach((ch) => {
        allChannels.add(ch.channel);
      });
    });

    // 2) Flatten each "month" into an object that has
    //    { yearMonth: 'YYYY-MM', Direct: X, Organic Search: Y, etc. }
    const flattened = analyticsData.map((monthItem) => {
      const base: any = { yearMonth: monthItem.yearMonth };

      // For each channel in this month's array, store the sessions
      monthItem.channels.forEach((ch) => {
        base[ch.channel] = ch.sessions;
      });

      // If a channel doesn't exist this month, set 0 for Recharts
      allChannels.forEach((channel) => {
        if (base[channel] === undefined) {
          base[channel] = 0;
        }
      });

      return base;
    });

    // 3) Sort the flattened array by yearMonth (ascending), if needed
    flattened.sort((a, b) => a.yearMonth.localeCompare(b.yearMonth));

    // 4) Convert allChannels to an array (for creating <Bar> elements)
    const channelArray = Array.from(allChannels);

    setStackedData(flattened);
    setChannelGroups(channelArray);
  }, [analyticsData]);

  // Optionally, you can create a custom tooltip if you want to style it.
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-2 bg-white border rounded-md shadow">
          <p className="font-semibold text-gray-700">{moment(label, "YYYY-MM").format("MMM YYYY")}</p>
          {payload.map((bar: any) => (
            <p key={bar.name} style={{ color: bar.fill }}>
              {`${bar.name}: ${bar.value}`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="flex w-full h-64">
      {error && <p className="m-auto text-sm text-center text-foreground-400">{error}</p>}
      {!error && (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={stackedData}>
            <XAxis
              dataKey="yearMonth"
              tickFormatter={(tick) => moment(tick, "YYYY-MM").format("MMM")}
              tick={{ fill: "#64748b", fontSize: "14", fontWeight: 300 }}
              axisLine={false}
              tickLine={false}
            />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend iconSize={10} />

            {/* For each channel, render a stacked Bar (stackId="channelsStack") */}
            {channelGroups.map((channel) => (
              <Bar
                key={channel}
                dataKey={channel}
                stackId="channelsStack"
                fill={getColor(channel, 20, 80, 1)}
                barSize={10}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}
