import { Button, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";
import React, { useEffect } from "react";
import { client } from "../../utils/client";
import { useParams } from "react-router-dom";

export default function Texts() {
  const { reportId } = useParams();
  const [texts, setTexts] = React.useState<any>(null);

  const fetchTexts = async () => {
    if (!reportId) return;
    try {
      const fetchedTexts = await client.service("reports").getTexts({ reportId: reportId, month: "" });
      setTexts(fetchedTexts);
      console.log("Fetched texts:", fetchedTexts);
    } catch (error) {
      console.error("Error fetching texts:", error);
    }
  };

  useEffect(() => {
    fetchTexts();
  }, [reportId]);

  // Function to approve a text
  const approveText = async (textId: string) => {
    await client.service("reports").approveText({ id: textId });
    fetchTexts();
  };

  return (
    <>
      {texts && texts.length > 0 && (
        <Table
          classNames={{
            wrapper: "shadow-none",
          }}
          aria-label="Example static collection table"
        >
          <TableHeader>
            <TableColumn>TEXTER ATT GODKÄNNA</TableColumn>
            <TableColumn>LÄNK</TableColumn>
            <TableColumn>GODKÄNN</TableColumn>
          </TableHeader>
          <TableBody>
            {texts?.map((text: any) => (
              <TableRow key={text._id.toString()}>
                <TableCell>
                  <div className="cursor-pointer hover:underline">
                    <div onClick={() => window.open(text.surfer.link, "_blank")} className="text-blue-600 ">
                      {text.title}
                    </div>
                    <div className="text-xs text-slate-600">{text.metadescription}</div>
                  </div>
                </TableCell>
                <TableCell>
                  <Button onClick={() => window.open(text.surfer.link, "_blank")} size="sm" color="default">
                    Se text
                  </Button>
                </TableCell>
                <TableCell>
                  <Button onClick={() => approveText(text._id.toString())} size="sm" color="success">
                    Godkänn
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
}
