import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ActivityTable from "./ActivityTable";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { Avatar, Button, Checkbox, Input, Select, SelectedItems, Selection, SelectItem } from "@nextui-org/react";
import { useAuth } from "../../context/AuthContext";
import { client, Task, User } from "../../utils/client";
import { DataContext } from "../../context/DataContext";
import { useModal } from "../../hooks/useModal";
import TaskForm from "../../components/Forms/TaskForm";

export default function Activities() {
  const { user } = useAuth();
  const { users, tasks } = useContext(DataContext);
  const [selectedUser, setSelectedUser] = useState<Selection>(new Set([user!._id.toString()]));
  const [allTasks, setAllTasks] = useState<Task[]>([]);
  const [showCompletedTasks, setShowCompletedTasks] = useState(false);
  const [showMyTasks, setShowMyTasks] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const { showModal } = useModal();

  const hasSearchFilter = Boolean(filterValue);

  type Query = {
    completed?: boolean;
    createdBy?: any;
    assignedTo?: any;
  };

  useEffect(() => {
    // if showCompletedTasks is true, query for all tasks
    const query: Query = {
      completed: showCompletedTasks ? undefined : false,
    };

    if (showMyTasks) {
      query.createdBy = Array.from(selectedUser)[0];
    } else {
      query.assignedTo = Array.from(selectedUser)[0];
    }

    console.log("query:", query);

    const fetchTasks = async () => {
      const allTasks = await client.service("tasks").getAllTasks(query, {});
      setAllTasks(allTasks);
    };
    fetchTasks();
  }, [tasks, selectedUser, showCompletedTasks, showMyTasks]);

  const filteredItems = useMemo(() => {
    let filteredTasks = [...allTasks];

    // Sort by deadline, just use the numbervalue and sort by that if deadline is defined otherwise sort by createdAt

    filteredTasks = filteredTasks.sort((a, b) => {
      const deadlineA = a.deadline ? a.deadline.valueOf() : a.createdAt?.valueOf();
      const deadlineB = b.deadline ? b.deadline.valueOf() : b.createdAt?.valueOf();
      return deadlineA! - deadlineB!;
    });

    // Search on task.text or task.description or customer.name
    if (hasSearchFilter) {
      filteredTasks = filteredTasks.filter((task) => {
        const searchValue = filterValue.toLowerCase();
        return task.text.toLowerCase().includes(searchValue) || task.description?.toLowerCase().includes(searchValue);
      });
    }
    return filteredTasks;
  }, [allTasks, filterValue, tasks, showCompletedTasks, showMyTasks]);

  // Filter for tasks with deadline last week and older
  const missedDeadlines = filteredItems.filter((task) => {
    const now = new Date();
    const startOfThisWeek = new Date();
    startOfThisWeek.setDate(now.getDate() - now.getDay());
    startOfThisWeek.setHours(0, 0, 0, 0);
    return task.deadline && task.deadline < startOfThisWeek.getTime() && (showCompletedTasks || !task.completed);
  });

  // Filter for tasks due this week
  const thisWeekTasks = filteredItems.filter((task) => {
    const now = new Date();
    const startOfThisWeek = new Date();
    startOfThisWeek.setDate(now.getDate() - now.getDay());
    startOfThisWeek.setHours(0, 0, 0, 0);

    const endOfThisWeek = new Date(startOfThisWeek);
    endOfThisWeek.setDate(startOfThisWeek.getDate() + 6);
    endOfThisWeek.setHours(23, 59, 59, 999);

    return task.deadline && task.deadline >= startOfThisWeek.getTime() && task.deadline <= endOfThisWeek.getTime();
  });

  // Filter for upcoming tasks next week and forward and tasks without deadline
  const upcomingTasks = filteredItems.filter((task) => {
    const now = new Date();
    const startOfNextWeek = new Date();
    startOfNextWeek.setDate(now.getDate() - now.getDay() + 7); // Start of next week
    startOfNextWeek.setHours(0, 0, 0, 0);
    return !task.deadline || task.deadline >= startOfNextWeek.getTime();
  });
  const onSearchChange = useCallback((value: string) => {
    if (value) {
      setFilterValue(value);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = useCallback(() => {
    setFilterValue("");
  }, []);

  return (
    <>
      <div className="flex justify-between mb-10">
        <h1 className="text-2xl font-medium text-gray">Aktiviteter</h1>
      </div>
      <div className="flex p-4 mb-4 space-x-4 bg-white rounded-xl">
        <Input
          isClearable
          size="lg"
          className="w-full"
          placeholder="Sök..."
          startContent={<MagnifyingGlassIcon className="w-4 h-4" />}
          value={filterValue}
          onClear={() => onClear()}
          onValueChange={onSearchChange}
        />
        <Checkbox isSelected={showCompletedTasks} onValueChange={setShowCompletedTasks}>
          <div className="text-xs whitespace-nowrap">Visa klara</div>
        </Checkbox>
        <Checkbox isSelected={showMyTasks} onValueChange={setShowMyTasks}>
          <div className="text-xs whitespace-nowrap">Visa skapade</div>
        </Checkbox>
        <Select
          label="Filtrera på användare"
          variant="flat"
          items={users.data}
          color="default"
          size="sm"
          placeholder="Välj användare"
          selectedKeys={selectedUser}
          className="max-w-xs"
          onSelectionChange={setSelectedUser}
          renderValue={(users: SelectedItems<User>) => {
            return users.map((user) => (
              <div key={user.data?._id.toString()} className="flex items-center gap-2">
                <Avatar alt={user.data?.fullName} className="flex-shrink-0 w-4 h-4" src={user.data?.profilePicture} />
                <div className="flex flex-col">
                  <span>{user.data?.fullName}</span>
                </div>
              </div>
            ));
          }}
        >
          {(user) => (
            <SelectItem key={user._id.toString()}>
              <div className="flex items-center gap-2">
                <Avatar alt={user.fullName} className="flex-shrink-0" size="sm" src={user.profilePicture} />
                <div className="flex flex-col">
                  <span className="text-small">{user.fullName}</span>
                  <span className="text-tiny text-default-400">{user.email}</span>
                </div>
              </div>
            </SelectItem>
          )}
        </Select>
        <Button className="my-auto shrink-0" color="primary" onClick={() => showModal(<TaskForm />, "Skapa aktivitet")}>
          Skapa aktivitet
        </Button>
      </div>
      <div className="space-y-4">
        <div>
          <ActivityTable title="Tidigare" key={"1"} tasks={missedDeadlines} />
        </div>
        <div>
          <ActivityTable title="Denna vecka" key={"2"} tasks={thisWeekTasks} />
        </div>
        <div>
          <ActivityTable title="Kommande" key={"3"} tasks={upcomingTasks} />
        </div>
      </div>
    </>
  );
}
