import React from "react";
import PieChart from "../../components/Charts/PieChart";
import CustomerTeam from "../Customers/CustomerTeam";
import TrafficChart from "../../components/Charts/TrafficChart";
import Texts from "./Texts";
import Log from "./Log";

type MainProps = {
  report: any;
  month: any;
};

export default function Main({ report, month }: MainProps) {
  const hasService = (service: string) => !report.services.some((s: any) => s.type === service);

  // Get the traffic entry for the currently selected month
  const selectedTraffic = report.traffic?.find((t: any) => t.yearMonth === month.format("YYYY-MM"));

  // Grab SEO (Organic Search), SEM (Paid Social) sessions, etc.
  const seoSessions = selectedTraffic?.channels?.find((ch: any) => ch.channel === "Organic Search")?.sessions || 0;

  const semSessions = selectedTraffic?.channels?.find((ch: any) => ch.channel === "Paid Search")?.sessions || 0;

  // Engagement rate for CRO in percentage
  const croValue = selectedTraffic?.total?.engagementRate ? Math.round(selectedTraffic.total.engagementRate * 100) : 0;

  const hasSEO = hasService("SEO");
  const hasSEM = hasService("SEM");
  const hasCRO = hasService("CRO");

  return (
    <>
      <div className="grid grid-cols-3 col-span-2 gap-2 auto-rows-min lg:gap-6">
        <div className="flex panel">
          <PieChart
            title="Måluppfyllnad SEO"
            description="Antal besökare SEO"
            label="Testing"
            value={seoSessions}
            goal={12000}
            color="#006fee"
          />
        </div>
        <div className="flex panel">
          <PieChart
            title="Måluppfyllnad SEM"
            description="Antal besökare SEM"
            label="Testing"
            value={semSessions}
            goal={7000}
            color="#7828c8"
          />
        </div>
        <div className="flex panel">
          <PieChart
            title="Måluppfyllnad CRO"
            description="Engagemangsgrad"
            explanation="Engagemangsgrad visar andelen besökare som aktivt interagerar med din webbplats eller app genom att stanna längre än 10 sekunder, utlösa en händelse eller besöka flera sidor."
            label="Testing"
            value={croValue}
            goal={72}
            color="#17c964"
            percentage={true}
          />
        </div>
      </div>
      <div className="w-full col-span-1">
        <CustomerTeam team={report.team} />
      </div>
      <div className="w-full grid-cols-2 gap-2 lg:grid col-span-full lg:gap-6">
        <Log notes={report.notes} />
        <Texts />
      </div>
    </>
  );
}
