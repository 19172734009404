import React, { useEffect, useState } from "react";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from "recharts";
import moment from "moment";
import { client, Customer } from "../../utils/client";

interface MonthlyEngagementItem {
  yearMonth: string;
  engagementRate: number;
}

type MonthlyEngagementChartProps = {
  customer: Customer;
};

export default function MonthlyEngagementChart({ customer }: MonthlyEngagementChartProps) {
  const [monthlyData, setMonthlyData] = useState<MonthlyEngagementItem[]>([]);
  const [error, setError] = useState<string | null>(null);

  const oneYearAgo = moment().subtract(1, "year").startOf("month").format("YYYY-MM-DD");

  useEffect(() => {
    const fetchMonthlyEngagement = async () => {
      try {
        const response = await client.service("analytics").getMonthlyEngagementRate(
          {
            startDate: oneYearAgo,
            endDate: "today",
            gaPropertyId: customer.gaPropertyId,
          },
          {}
        );

        if (!response || response.length === 0) {
          setError(
            "Kunde inte hämta data från Google Analytics. Lägg till användaren ga4-data-api-service@new-customers-report.iam.gserviceaccount.com som läsbehörig."
          );
        } else {
          setMonthlyData(response);
        }
      } catch (error) {
        console.error("Error fetching monthly engagement data:", error);
        setError("Något gick fel när månadsdata skulle hämtas.");
      }
    };

    fetchMonthlyEngagement();
  }, [customer.gaPropertyId]);

  // Optional: you can create a custom tooltip for better formatting
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-2 bg-white border rounded-md shadow">
          <p className="font-semibold text-gray-700">{moment(label, "YYYY-MM").format("MMM YYYY")}</p>
          <p>Engagement Rate: {(payload[0].value * 100).toFixed(1)}%</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="flex w-full h-64">
      {error && <p className="m-auto text-sm text-center text-foreground-400">{error}</p>}
      {!error && (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={monthlyData}>
            <XAxis
              dataKey="yearMonth"
              tickFormatter={(tick) => moment(tick, "YYYY-MM").format("MMM")}
              tick={{ fill: "#64748b", fontSize: "14", fontWeight: 300 }}
              axisLine={false}
              tickLine={false}
            />
            <YAxis
              // Engagement rate is typically a fraction (0-1), so you might want a domain like [0, 1]
              domain={[0, 1]}
              tickFormatter={(val) => `${(val * 100).toFixed(0)}%`}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />

            <Bar dataKey="engagementRate" fill="#8884d8" barSize={20} />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}
