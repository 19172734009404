import React from "react";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from "recharts";
import moment from "moment";

interface ChannelType {
  channel: string;
  sessions: number;
  engagementRate: number;
  engagedSessions: number;
  conversions: number;
  bounceRate: number;
}

interface TrafficDataItem {
  yearMonth: string;
  total: {
    sessions: number;
    engagedSessions: number;
    conversions: number;
    engagementRate: number;
  };
  channels: ChannelType[];
}

// The metric names here should match keys on the ChannelType interface
type MetricKey = keyof ChannelType;

type DynamicBarChartProps = {
  traffic: TrafficDataItem[];
  /** e.g. "Organic Search" */
  selectedChannel: string;
  /** e.g. "sessions", "engagementRate", etc. */
  selectedMetric: MetricKey;
  /** Label for the legend (e.g. "Organic Sessions") */
  barName: string;
  /** Color for the bar */
  color: string;
};

export default function DynamicBarChart({
  traffic,
  selectedChannel,
  selectedMetric,
  barName,
  color,
}: DynamicBarChartProps) {
  /**
   * Build chart data based on the selected channel & metric.
   * Example: If selectedChannel = "Organic Search",
   * and selectedMetric = "sessions",
   * we produce an array of { yearMonth, value }.
   */
  const chartData = React.useMemo(() => {
    return traffic.map((monthItem) => {
      const channelData = monthItem.channels.find((c) => c.channel === selectedChannel);
      // If the channel isn't found for that month, fallback to 0
      let rawValue = channelData ? channelData[selectedMetric] : 0;

      // If it's engagementRate, you might want to show it in percent
      if (selectedMetric === "engagementRate" || selectedMetric === "bounceRate") {
        rawValue = Number(rawValue) * 100;
      }

      return {
        yearMonth: monthItem.yearMonth,
        value: rawValue,
      };
    });
  }, [traffic, selectedChannel, selectedMetric]);

  // Sort by yearMonth if needed
  chartData.sort((a, b) => a.yearMonth.localeCompare(b.yearMonth));

  // Format Y-axis if showing engagementRate or bounceRate
  const isPercentage = selectedMetric === "engagementRate" || selectedMetric === "bounceRate";
  const yAxisDomain = isPercentage ? [0, 100] : undefined; // 0-100% if engagementRate
  const yAxisFormatter = (val: number) => {
    return isPercentage ? `${val.toFixed(0)}%` : val.toString();
  };

  const customTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const displayValue = isPercentage ? `${payload[0].value.toFixed(1)}%` : payload[0].value;
      return (
        <div className="p-2 bg-white border rounded-md shadow">
          <p className="font-semibold text-gray-700">{moment(label, "YYYY-MM").format("MMM YYYY")}</p>
          <p>{`${barName}: ${displayValue}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="w-full h-64">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={chartData}>
          <XAxis dataKey="yearMonth" tickFormatter={(tick) => moment(tick, "YYYY-MM").format("MMM")} stroke="#64748b" />
          <YAxis yAxisId="left" stroke="#64748b" domain={yAxisDomain} tickFormatter={yAxisFormatter} />
          <Tooltip content={customTooltip} />
          <Legend />
          <Bar dataKey="value" yAxisId="left" name={barName} fill={color} radius={[15, 15, 0, 0]} barSize={8} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
