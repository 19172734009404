import React, { useContext, useEffect, useState } from "react";
import { Tooltip, BarChart, Bar, ResponsiveContainer, XAxis } from "recharts";
import moment from "moment";
import { client } from "../../utils/client";
import { DataContext } from "../../context/DataContext";

export default function ActivityChart() {
  const { tasks } = useContext(DataContext);
  const [tasksCount, setTasksCount] = useState(0);
  const [taskStats, setTaskStats] = useState([]);

  useEffect(() => {
    const fetchTasks = async () => {
      const tasks = await client.service("tasks").find({
        query: { completed: false },
      });
      setTasksCount(tasks.total);
    };
    const fetchStats = async () => {
      const stats = await client.service("tasks").getTaskStats({}, {});
      console.log(stats);
      setTaskStats(stats); // Directly set the received stats
    };

    fetchStats();
    fetchTasks();
  }, [tasks]);

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-4 bg-white border custom-tooltip rounded-xl border-grayLight">
          <p style={{ color: payload[0].fill }} className="label">{`Planerade timmar: ${payload[0].value}`}</p>
          <p style={{ color: payload[1].fill }} className="label">{`Arbetade timmar: ${payload[1].value}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="w-full h-auto p-6 rounded-lg panel">
      <div className="">
        <div className="text-xl font-medium text-gray">{tasksCount}</div>
        <div className="text-xs text-default-500">Öppna aktiviteter</div>
      </div>
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart height={100} data={taskStats}>
            <Tooltip content={<CustomTooltip />} />
            <Bar radius={[5, 5, 0, 0]} type="natural" barSize={10} dataKey="plannedHours" fill="#51513D" />
            <Bar radius={[5, 5, 0, 0]} type="natural" barSize={10} dataKey="workedHours" fill="#D5A438" />
            <XAxis
              padding={{ left: 40, right: 20 }}
              tick={{ fill: "#64748b", fontSize: "14", fontWeight: 300 }}
              axisLine={false}
              tickLine={false}
              dataKey="_id"
              tickFormatter={(tick) => moment(tick, "YYYYMM").format("MMM")} // Format to short month name
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
