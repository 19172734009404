import { useEffect, useState } from "react";
import { Customerservice, Keyword, client } from "../../utils/client";
import {
  Button,
  Input,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  getKeyValue,
} from "@nextui-org/react";
import { useModal } from "../../hooks/useModal";

type ContentFormProps = {
  keywords: Keyword[];
  service: Customerservice;
};
export default function TextLinkForm({ keywords, service }: ContentFormProps) {
  const columns = [{ key: "keyword", label: "SÖKORD" }];
  const [surferId, setSurferId] = useState<number>();

  const { closeModal } = useModal();

  const handleOrderContent = async () => {
    try {
      await client.service("texts").create({
        serviceId: service._id.toString(),
        customerId: service.customerId.toString(),
        surfer: {
          id: surferId,
          keywords: keywords.map((keyword) => keyword._id.toString()),
        },
      });
      closeModal();
    } catch (error) {
      console.error("Error ordering content", error);
    }
  };

  return (
    <div className="space-y-5">
      <Table
        classNames={{
          wrapper: "shadow-none p-0",
        }}
        aria-label="Example table with dynamic content"
      >
        <TableHeader columns={columns}>
          {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
        </TableHeader>
        <TableBody items={keywords}>
          {(item) => (
            <TableRow key={item._id.toString()}>
              {(columnKey) => <TableCell>{getKeyValue(item, columnKey)}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div>
        <Input placeholder="Surfer ID" type="number" onChange={(e) => setSurferId(Number(e.target.value))} />
      </div>
      <div className="flex justify-end space-x-5">
        <Button onClick={handleOrderContent} size="sm" color="primary">
          Länka text
        </Button>
      </div>
    </div>
  );
}
