export function darkenHex(hexColor: string, ratio = 0.2): string {
  // Remove the leading '#' if present
  let color = hexColor.replace("#", "");

  // Parse r, g, b from hex
  let r = parseInt(color.substring(0, 2), 16);
  let g = parseInt(color.substring(2, 4), 16);
  let b = parseInt(color.substring(4, 6), 16);

  // Darken each channel by ratio
  r = Math.floor(r * (1 - ratio));
  g = Math.floor(g * (1 - ratio));
  b = Math.floor(b * (1 - ratio));

  // Convert back to hex and return
  const rr = (r < 16 ? "0" : "") + r.toString(16);
  const gg = (g < 16 ? "0" : "") + g.toString(16);
  const bb = (b < 16 ? "0" : "") + b.toString(16);
  return `#${rr}${gg}${bb}`;
}
