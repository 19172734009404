import { Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import Layout from "./Layout";
import Dashboard from "./pages/Dashboard";
import ProtectedRoutes from "./ProtectedRoutes";
import Admin from "./pages/Admin";
import NoMatch from "./pages/NoMatch";
import Login from "./pages/Login";
import Customers from "./pages/Customers/Customers";
import Customer from "./pages/Customers/Customer";
import Services from "./pages/Services/Services";
import Service from "./pages/Services/Service";
import Activities from "./pages/Activities/Activities";
import Texts from "./pages/Texts/Texts";
import Report from "./pages/Report/Report";

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route index element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route element={<ProtectedRoutes />}>
          <Route element={<Layout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="activities" element={<Activities />} />
            <Route path="customers">
              <Route index element={<Customers />} />
              <Route path=":id" element={<Customer />} />
            </Route>
            <Route path="services">
              <Route index element={<Services />} />
              <Route path=":id" element={<Service />} />
            </Route>
            <Route path="texts" element={<Texts />} />
            <Route path="admin" element={<Admin />} />
          </Route>
        </Route>
        <Route path="/report/:reportId" element={<Report />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </AuthProvider>
  );
}
