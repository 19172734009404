const colorCache = new Map<string, string>();

export default function getColor(str: string, s = 50, l = 80, opacity = 1): string {
  if (str === "Organic Search") {
    return "#4285f4";
  }

  if (str === "Paid Search") {
    return "#fbbd04";
  }

  // If we have a cached value, return it
  const cacheKey = `${str}_${s}_${l}_${opacity}`;
  if (colorCache.has(cacheKey)) {
    return colorCache.get(cacheKey)!;
  }

  // 1. Normalize the input (optional)
  const normalized = str.toLowerCase();

  // 2. Compute a simple hash
  let hash = 0;
  for (let i = 0; i < normalized.length; i++) {
    hash = normalized.charCodeAt(i) + ((hash << 5) - hash);
  }

  // 3. Ensure hue is non-negative
  const hue = ((hash % 360) + 360) % 360;

  // 4. Build hsla() color string
  const color = `hsla(${hue}, ${s}%, ${l}%, ${opacity})`;

  // 5. Cache it for later
  colorCache.set(cacheKey, color);

  return color;
}
